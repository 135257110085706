import { Box, Stack } from 'styles';
import { IClaim } from 'types/Claim';
import { DocType } from 'types/Upload';
import SelectPrescription from '../SelectPrescription/SelectPrescription';
import DownloadAttestation from './DownloadAttestation';

type docLinksProps = {
	formData: IClaim;
	docList: DocType[];
};

const DocumentationLinks = ({ formData, docList }: docLinksProps) => {
	const width = '100%';

	return (
		<Stack direction={'row'} gap="40px" alignItems={'flex-end'}>
			{docList.find((doc: DocType) => doc === 'prescription') && (
				<Box width={width}>
					<SelectPrescription disabled={!!formData.attachments?.prescription?.id} />
				</Box>
			)}
			<Box width={width}></Box>
			<Box width={width}>
				<DownloadAttestation />
			</Box>
		</Stack>
	);
};

export default DocumentationLinks;
