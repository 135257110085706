import { ThunkApiType } from 'types/storeTypes';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { getLocaleLanguage } from 'utils/utils';

export const getLimitationsThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	const lang = getLocaleLanguage();

	const {
		member: { benefit },
	} = thunkAPI.getState().member;

	const getLimitationsURL = `/cms/limitations?locale=${lang}&populate=*&filters[plan_name][%24eq]=${benefit.planId}`;

	try {
		const response = await authFetch.get(getLimitationsURL);
		return response.data.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
