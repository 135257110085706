import {
	setFilePromise,
	setFileUpload,
	uploadDocument,
} from 'feature/upload/uploadSlice';
import _ from 'lodash';
import {
	ChangeEvent,
	DragEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { useTranslate } from 'stylesHooks';
import { DocType } from 'types/Upload';
import { TRANSLATION_KEYS } from 'utils/constants';

const AUTHORIZED_FILE_EXTENSIONS = [
	'application/pdf',
	'image/jpeg',
	'image/jpg',
	'image/png',
];

export const MAX_FILE_SIZE_MB = 20;
export const MAX_SIZE_IN_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // 20MB
export const MAX_OTHER_FILE_NUMBER = 5;

export function verifyAccept(type: string): boolean {
	return AUTHORIZED_FILE_EXTENSIONS.includes(type);
}

export const useUploadFile = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const [dragActive, setDragActive] = useState(false);
	const [dragError, setDragError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handlerFile = useCallback(
		(files: FileList | null, documentType: DocType, maxFileNumber: number) => {
			if (files && files.length !== 0) {
				// VALIDATION CHECKS
				if (files.length > maxFileNumber) {
					setDragError(true);
					setErrorMessage(tClientLabels('error.maxFileNumber') as string);
					return;
				}

				for (let i = 0; i < files.length; i++) {
					const selectedFile = files[i];
					if (!verifyAccept(selectedFile.type)) {
						setDragError(true);
						setErrorMessage(tClientLabels('error.fileNotEnabled') as string);
						return;
					}
					if (selectedFile?.size && selectedFile?.size > MAX_SIZE_IN_BYTES) {
						setDragError(true);
						setErrorMessage(
							tClientLabels('error.fileSizeError', {
								size: MAX_FILE_SIZE_MB,
							}) as string,
						);
						return;
					}
				}

				//REAL UPLOAD
				for (let i = 0; i < files.length; i++) {
					const selectedFile = files[i];
					dispatch(setFileUpload(selectedFile));
					const promise = dispatch(
						uploadDocument({ showloader: true, docType: documentType }),
					);
					dispatch(setFilePromise(promise));
				}
			}
		},
		[dispatch, tClientLabels],
	);

	const handleUpload = useCallback(
		(
			event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
			documentType: DocType,
			maxFileNumber: number = 1,
		) => {
			if (event?.target && 'files' in event.target) {
				const { files } = event.target;
				event.preventDefault();

				handlerFile(files, documentType, maxFileNumber);
			} else if ('dataTransfer' in event) {
				const { dataTransfer } = event;
				handlerFile(dataTransfer.files, documentType, maxFileNumber);
			}
		},
		[handlerFile],
	);

	const handleDrag = useCallback(
		(evt: DragEvent<HTMLDivElement>, maxFileNumber: number = 1) => {
			evt.preventDefault();
			evt.stopPropagation();

			if (evt.dataTransfer.items.length > 1 && maxFileNumber === 1) {
				setDragError(true);
				setErrorMessage(tClientLabels('error.uploadOneFileOnly'));
				evt.dataTransfer.effectAllowed = 'none';
				evt.dataTransfer.dropEffect = 'none';
				return;
			}
			if (evt.dataTransfer.items.length > maxFileNumber) {
				setDragError(true);
				setErrorMessage(tClientLabels('error.maxFileNumber'));
				evt.dataTransfer.effectAllowed = 'none';
				evt.dataTransfer.dropEffect = 'none';
				return;
			}

			if (evt.type === 'dragenter' || evt.type === 'dragover') {
				setDragActive(true);

				for (let i = 0; i < evt.dataTransfer.items.length; i++) {
					const item = evt.dataTransfer.items[i];
					if (!verifyAccept(item.type)) {
						setDragError(true);
						setErrorMessage(tClientLabels('error.fileNotEnabled') as string);
						return;
					}
				}
			} else if (evt.type === 'dragleave') {
				setDragActive(false);
				setDragError(false);
			}
		},
		[tClientLabels],
	);

	const handleDrop = useCallback(
		(
			evt: DragEvent<HTMLDivElement>,
			callBack: (
				event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
			) => void,
			maxFileNumber: number = 1,
		) => {
			evt.preventDefault();
			evt.stopPropagation();

			if (evt.dataTransfer.items.length > 1 && maxFileNumber === 1) {
				setDragError(true);
				setErrorMessage(tClientLabels('error.uploadOneFileOnly') as string);
				evt.dataTransfer.effectAllowed = 'none';
				evt.dataTransfer.dropEffect = 'none';
				return;
			}

			if (evt.dataTransfer.items.length > maxFileNumber) {
				setDragError(true);
				setErrorMessage(tClientLabels('error.maxFileNumber') as string);
				evt.dataTransfer.effectAllowed = 'none';
				evt.dataTransfer.dropEffect = 'none';
				return;
			}

			for (let i = 0; i < evt.dataTransfer.items.length; i++) {
				if (!verifyAccept(evt.dataTransfer.items[i].type)) {
					setDragError(true);
					setErrorMessage(tClientLabels('error.fileNotEnabled') as string);
					return;
				}

				const file = evt.dataTransfer.items[i].getAsFile();
				if (!_.isNull(file) && file.size > MAX_SIZE_IN_BYTES) {
					setDragError(true);
					setErrorMessage(
						tClientLabels('error.fileSizeError', {
							size: MAX_FILE_SIZE_MB,
						}) as string,
					);
					return;
				}
			}

			setDragActive(false);
			callBack(evt);
		},
		[tClientLabels],
	);

	useEffect(() => {
		if (!!dragError && errorMessage !== '') {
			setTimeout(() => {
				setDragError(false);
				setErrorMessage('');
			}, 5000);
		}
	}, [errorMessage, dragError]);

	return useMemo(
		() => ({
			upload: handleUpload,
			drag: handleDrag,
			drop: handleDrop,
			dragStatus: {
				isActive: dragActive,
				onError: dragError,
				errorMessage,
			},
		}),
		[handleUpload, handleDrag, handleDrop, dragActive, dragError, errorMessage],
	);
};
