import { FC } from 'react';
import { IFileType } from 'types/Claim';
import { DocType } from 'types/Upload';
import BeforeUpload from './BeforeUpload';
import { Box, Stack } from 'styles';
import ManyAttachmentRow from './ManyAttachmentRow';
import { MAX_OTHER_FILE_NUMBER } from 'hooks/uploadFile.hook';

type UploadManyTileProps = {
	documentType: DocType;
	data?: IFileType[];
};

const UploadManyTile: FC<UploadManyTileProps> = ({ documentType, data }) => {
	return (
		<>
			<Box width={'100%'} minHeight={{ sm: '210px', lg: '240px' }}>
				<Stack direction={'column'} gap={'20px'}>
					<BeforeUpload
						documentType={documentType}
						maxFileNumber={
							data ? MAX_OTHER_FILE_NUMBER - data.length : MAX_OTHER_FILE_NUMBER
						}
					/>
					{data?.map((attachment, index) => (
						<ManyAttachmentRow
							key={index}
							data={attachment}
							fileName={attachment?.fileName}
							documentType={documentType}
						/>
					))}
				</Stack>
			</Box>
		</>
	);
};

export default UploadManyTile;
