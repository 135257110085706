import { ReactComponent as Basket } from 'assett/icons/basket.svg';
import { ReactComponent as Pencil } from 'assett/icons/pencil.svg';
import { ReactComponent as UploadCompleted } from 'assett/icons/upload_completed.svg';
import useDevice from 'hooks/DesktopDevice.hook';
import { useUploadFile } from 'hooks/uploadFile.hook';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from 'styles';
import { theme } from 'styles/theme/ThemeStyle';
import { IFileType } from 'types/Claim';
import { DocType } from 'types/Upload';
import DeletePopup from './DeletePopup';
import FileNameEditor from './FileNameEditor';

interface AfterUploadProps {
	isEditMode: boolean;
	enableEditModeAction: () => void;
	enableDialogAction: () => void;
	handlerDownloadFile?: () => void;
	canDownloadFile?: boolean;
	label: string;
	data?: IFileType;
	documentType: DocType;
	setIsEditMode: Dispatch<SetStateAction<boolean>>;
}

const AfterUpload: React.FC<AfterUploadProps> = ({
	enableEditModeAction,
	isEditMode,
	enableDialogAction,
	handlerDownloadFile,
	label,
	canDownloadFile,
	data,
	documentType,
	setIsEditMode,
}) => {
	const { isDesktop } = useDevice();
	const [dialogDeleteOpened, setDialogDeleteOpened] = useState(false);

	const uploadFileHandler = useUploadFile();

	return (
		<>
			{data ? (
				<DeletePopup
					data={{ id: data.id, fileName: data.fileName || '' }}
					documentType={documentType}
					openDialog={dialogDeleteOpened}
					setOpenDialog={setDialogDeleteOpened}
				/>
			) : null}
			<Stack
				direction={'column'}
				justifyContent={'stretch'}
				gap={'10px'}
				bgcolor={theme.palette.white.main}
				borderRadius={'4px'}
				px={'20px'}
				py={isDesktop ? '20px' : '10px'}
				height={isDesktop ? '200px' : '164px'}
				width="100%"
				sx={{
					border: '1px solid',
					borderColor: uploadFileHandler.dragStatus.onError
						? theme => theme.palette.error.main
						: theme.palette.primary1.main,
				}}
			>
				<Box display="flex" justifyContent="flex-end" gap="1.25rem">
					<IconButton onClick={enableEditModeAction}>
						<Pencil />
					</IconButton>
					<IconButton disableTouchRipple onClick={() => setDialogDeleteOpened(true)}>
						<Basket />
					</IconButton>
				</Box>
				<Stack height={'100%'} justifyContent={'space-between'}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<Box
							display="flex"
							p={0}
							component={canDownloadFile ? Button : undefined}
							onClick={handlerDownloadFile}
						>
							<UploadCompleted />
						</Box>
						<Typography variant={isDesktop ? 'value1' : 'bodyLink'}>
							{label}
						</Typography>
					</Box>
					<FileNameEditor
						documentType={documentType}
						data={data}
						isEditMode={isEditMode}
						setIsEditMode={setIsEditMode}
					/>
				</Stack>
			</Stack>
		</>
	);
};

export default AfterUpload;
