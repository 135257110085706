import { useMemo } from 'react';
import { Eye, IEyePrescription, IPrescription } from 'types/Claim';

export type columnProperties = {
	type: 'textField' | 'autocomplete';
	required: boolean;
	title: string;
	property: keyof IEyePrescription;
	neededContactsOld: boolean; //is this field needed also for old prescriptions or contacts
	formatValueVideo?: (value: string) => string;
	formatValueBackend?: (value: string) => number | string | null;
	findError?: (eye: Eye.od | Eye.os) => string;
	tooltip?: string;
	options?: { value: string; descr: string }[];
};

const addSign = (value: string) =>
	value.substring(0, 1) === '+' || value.substring(0, 1) === '-' ? '' : '+';

const getOtherEye = (eye: Eye.od | Eye.os) =>
	eye === Eye.od ? Eye.os : Eye.od;

const formatValueVideoDecimals =
	(withSign: boolean) => (value: string | number) => {
		if (!value && value !== 0) return '';
		value = String(value);
		if (isNaN(Number(value))) return value;
		const numberParts = String(value).split(/[,,.]/);
		return `${withSign ? addSign(value) : ''}${numberParts[0]}.${(
			numberParts[1] || ''
		)
			.concat('00')
			.substring(0, 2)}`;
	};

const formatValueToNumber = (value: string) => {
	return value ? Number(value) : null;
};

const formatValueToString = (value: string) => {
	return value ? String(value) : null;
};

const checkInterval =
	(
		prescription: IPrescription,
		property: keyof IPrescription['os'],
		interval: { min: number; max: number; step: number; required?: boolean },
		errorMessage: string,
	) =>
	(eye: Eye.od | Eye.os) => {
		if (!interval.required && !prescription[eye][property]) {
			return '';
		}

		if (isNaN(Number(prescription[eye][property]))) {
			return errorMessage;
		}

		if (
			Number(prescription[eye][property]) % interval.step ||
			Number(prescription[eye][property]) > interval.max ||
			Number(prescription[eye][property]) < interval.min
		)
			return errorMessage;

		return '';
	};

export const prescriptionColumnsConfiguration: (config: {
	prescription: IPrescription;
	checkEmptyFields: boolean;
	isOldPrescription: boolean;
	isSingleVision: boolean;
}) => columnProperties[] = ({
	prescription,
	checkEmptyFields,
	isOldPrescription,
	isSingleVision,
}) => [
	{
		type: 'textField',
		required: true,
		title: 'sphere',
		property: 'sphere',
		neededContactsOld: true,
		formatValueVideo: formatValueVideoDecimals(true),
		formatValueBackend: formatValueToNumber,
		findError: eye => {
			if (isNaN(Number(prescription[eye].sphere))) {
				return 'range25';
			}

			if (!prescription[eye].sphere) {
				if (!checkEmptyFields) return '';
				return 'fieldRequired';
			}

			return Number(prescription[eye].sphere) % 0.25 ||
				Math.abs(Number(prescription[eye].sphere)) > 25
				? 'range25'
				: '';
		},
	},
	{
		type: 'textField',
		required: false,
		title: 'cylinder',
		property: 'cylinder',
		neededContactsOld: true,
		formatValueVideo: formatValueVideoDecimals(true),
		formatValueBackend: formatValueToNumber,
		findError: eye => {
			if (isNaN(Number(prescription[eye].cylinder))) {
				return 'range25';
			}

			switch (true) {
				case !!(Number(prescription[eye].cylinder) % 0.25):
				case !!(Math.abs(Number(prescription[eye].cylinder)) > 25):
					return 'range25';
				case !!(Number(prescription[getOtherEye(eye)].cylinder) % 0.25):
				case !!(Math.abs(Number(prescription[getOtherEye(eye)].cylinder)) > 25):
					return ''; //show further error only if both fields are ok
				case Number(prescription.od.cylinder) * Number(prescription.os.cylinder) <
					0:
					return 'prescriptionErrorSphere';
				default:
					return '';
			}
		},
	},
	{
		type: 'textField',
		required: false,
		title: 'axis',
		property: 'axis',
		neededContactsOld: true,
		formatValueBackend: formatValueToNumber,
		findError: eye => {
			if (isNaN(Number(prescription[eye].axis))) {
				return 'range180';
			}

			if (!prescription[eye].axis) {
				if (!checkEmptyFields) return '';
				if (!Number(prescription[eye].cylinder)) return '';
				return 'fieldRequired';
			}

			if (prescription[eye].axis) {
				if (!Number(prescription[eye].cylinder)) return 'axisShouldBeEmpty';
			}

			if (
				Number(prescription[eye].axis) % 1 ||
				Number(prescription[eye].axis) > 180 ||
				Number(prescription[eye].axis) < 1
			)
				return 'range180';

			return '';
		},
	},
	{
		type: 'textField',
		required: !isOldPrescription && !isSingleVision,
		title: 'addition',
		property: 'addition',
		neededContactsOld: true,
		formatValueVideo: formatValueVideoDecimals(true),
		formatValueBackend: formatValueToNumber,
		findError: eye => {
			if (isNaN(Number(prescription[eye].addition))) {
				return 'rangeAddition';
			}

			if (!prescription[eye].addition) {
				if (!checkEmptyFields) return '';
				if (isSingleVision) return '';
				return 'fieldRequired';
			}

			if (
				Number(prescription[eye].addition) % 0.25 ||
				Number(prescription[eye].addition) > 5 ||
				Number(prescription[eye].addition) < 0.25
			)
				return 'rangeAddition';

			return '';
		},
	},
	{
		type: 'textField',
		required: false,
		title: 'prismDioptresIO',
		property: 'prismDioptresIO',
		neededContactsOld: true,
		formatValueVideo: formatValueVideoDecimals(true),
		formatValueBackend: formatValueToNumber,
		findError: eye => {
			if (isNaN(Number(prescription[eye].prismDioptresIO))) {
				return 'rangePrism';
			}

			if (
				Number(prescription[eye].prismDioptresIO) % 0.25 ||
				Number(prescription[eye].prismDioptresIO) < 0 ||
				Math.abs(Number(prescription[eye].prismDioptresIO)) > 10
			)
				return 'rangePrism';

			if (
				!Number(prescription[eye].prismDioptresIO) &&
				prescription[eye].prismDirectionIO
			) {
				if (!checkEmptyFields) return '';
				return 'prismDioptresIORequired';
			}

			return '';
		},
	},
	{
		type: 'autocomplete',
		required: false,
		title: 'prismDirectionIO',
		property: 'prismDirectionIO',
		neededContactsOld: true,
		formatValueVideo: value => (value ? value : ''),
		formatValueBackend: formatValueToString,
		options: [
			{ value: 'In', descr: `newClaim.prescription.eyesPrescriton.domains.in` },
			{ value: 'Out', descr: `newClaim.prescription.eyesPrescriton.domains.out` },
		],
		findError: eye => {
			if (
				!prescription[eye].prismDirectionIO &&
				Number(prescription[eye].prismDioptresIO)
			) {
				if (!checkEmptyFields) return '';
				return 'rangePrismDirectionIO';
			}

			return '';
		},
	},
	{
		type: 'textField',
		required: false,
		title: 'prismDioptresUD',
		property: 'prismDioptresUD',
		neededContactsOld: true,
		formatValueVideo: formatValueVideoDecimals(true),
		formatValueBackend: formatValueToNumber,
		findError: eye => {
			if (isNaN(Number(prescription[eye].prismDioptresUD))) {
				return 'rangePrismDioptres';
			}

			if (
				Number(prescription[eye].prismDioptresUD) % 0.25 ||
				Number(prescription[eye].prismDioptresUD) < 0 ||
				Math.abs(Number(prescription[eye].prismDioptresUD)) > 10
			)
				return 'rangePrismDioptres';

			if (
				!Number(prescription[eye].prismDioptresUD) &&
				prescription[eye].prismDirectionUD
			) {
				if (!checkEmptyFields) return '';
				return 'prismDioptresUDRequired';
			}

			return '';
		},
	},
	{
		type: 'autocomplete',
		required: false,
		title: 'prismDirectionUD',
		property: 'prismDirectionUD',
		neededContactsOld: true,
		formatValueVideo: value => (value ? value : ''),
		formatValueBackend: formatValueToString,
		options: [
			{ value: 'Up', descr: `newClaim.prescription.eyesPrescriton.domains.up` },
			{
				value: 'Down',
				descr: `newClaim.prescription.eyesPrescriton.domains.down`,
			},
		],
		findError: eye => {
			if (
				!prescription[eye].prismDirectionUD &&
				Number(prescription[eye].prismDioptresUD)
			) {
				if (!checkEmptyFields) return '';
				return 'rangePrismDirectionUD';
			}

			return '';
		},
	},
	{
		type: 'textField',
		required: false,
		title: 'pd',
		property: 'pd',
		neededContactsOld: false,
		formatValueBackend: formatValueToNumber,
		findError: checkInterval(
			prescription,
			'pd',
			{ min: 22, max: 40, step: 1 },
			'range22_40',
		),
	},
	{
		type: 'textField',
		required: false,
		title: 'height',
		property: 'height',
		neededContactsOld: false,
		formatValueBackend: formatValueToNumber,
		findError: checkInterval(
			prescription,
			'height',
			{ min: 12, max: 40, step: 1 },
			'range12_40',
		),
	},
	{
		type: 'textField',
		required: false,
		title: 'vertexFitted',
		property: 'vertexFitted',
		neededContactsOld: false,
		formatValueBackend: formatValueToNumber,
		findError: checkInterval(
			prescription,
			'vertexFitted',
			{ min: 5, max: 20, step: 1 },
			'range5_20',
		),
	},
	{
		type: 'textField',
		required: false,
		title: 'faceformTilt',
		property: 'faceformTilt',
		neededContactsOld: false,
		formatValueBackend: formatValueToNumber,
		findError: checkInterval(
			prescription,
			'faceformTilt',
			{ min: 0, max: 25, step: 1 },
			'range0_25',
		),
	},
	{
		type: 'textField',
		required: false,
		title: 'pantoscopicTilt',
		property: 'pantoscopicTilt',
		neededContactsOld: false,
		formatValueBackend: formatValueToNumber,
		findError: checkInterval(
			prescription,
			'pantoscopicTilt',
			{ min: 0, max: 15, step: 1 },
			'range0_15',
		),
	},
];

export const useColumnConfiguration = (
	data: IPrescription,
	checkEmptyFields: boolean,
	isOldPrescription: boolean,
	isContactOnly: boolean,
	isSingleVision: boolean,
) => {
	const columnConfiguration = useMemo(() => {
		const allColumns = prescriptionColumnsConfiguration({
			prescription: data,
			checkEmptyFields,
			isOldPrescription,
			isSingleVision,
		});

		const columns =
			isOldPrescription || isContactOnly
				? allColumns.filter(el => el.neededContactsOld)
				: allColumns;

		const columnsErrors = columns.map(col =>
			[Eye.od, Eye.os].map(eye => {
				if (!col.findError) return '';
				return col.findError(eye);
			}),
		);

		return {
			columns,
			columnsErrors: {
				[Eye.od]: columns.map((col, index) => columnsErrors[index][0]),
				[Eye.os]: columns.map((col, index) => columnsErrors[index][1]),
			},
		};
	}, [checkEmptyFields, data, isContactOnly, isOldPrescription, isSingleVision]);

	return columnConfiguration;
};
