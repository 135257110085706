import {
	setDocumentId,
	setDocumentTypeSelected,
} from 'feature/upload/uploadSlice';
import { useFileOpener } from 'hooks/downloader.hook';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { Box } from 'styles';
import { IFileType } from 'types/Claim';
import { DocType } from 'types/Upload';
import { camelcaseToUnderscore } from 'utils/utils';
import AfterUpload from './AfterUpload';
import BeforeUpload from './BeforeUpload';

type DocumentationClaimProps = {
	label: string;
	labelUpload: string;
	documentType: DocType;
	data?: IFileType;
};

const UploadFileTile: FC<DocumentationClaimProps> = ({
	label,
	documentType,
	data,
}) => {
	const dispatch = useDispatch<AppDispatch>();

	const documentId = data?.id;
	const fileName = data?.fileName;
	const [isEditMode, setIsEditMode] = useState(false);

	const fileOpener = useFileOpener();
	const handlerDownloadFile = () => {
		const url = `${
			process.env.REACT_APP_API_URL
		}/attachments/${camelcaseToUnderscore(documentType)}/${documentId}/content`;

		fileOpener(url, fileName!);
	};

	return (
		<>
			<Box width={'100%'} minHeight={{ sm: '210px', lg: '240px' }}>
				{!data?.id ? (
					<BeforeUpload documentType={documentType} />
				) : (
					<AfterUpload
						isEditMode={isEditMode}
						enableEditModeAction={() => setIsEditMode(true)}
						enableDialogAction={() => {
							dispatch(setDocumentId(documentId));
							dispatch(setDocumentTypeSelected(documentType));
						}}
						label={label}
						canDownloadFile
						handlerDownloadFile={handlerDownloadFile}
						data={data}
						documentType={documentType}
						setIsEditMode={setIsEditMode}
					/>
				)}
			</Box>
		</>
	);
};

export default UploadFileTile;
