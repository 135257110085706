import { FC } from 'react';
import { Box, Button, Dialog, IconButton, Typography } from 'styles';

import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import { resetPrescriptionSelectedFromStorage } from 'feature/claims/claimSlice';
import { deleteDocument } from 'feature/upload/uploadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { useTranslate } from 'stylesHooks';
import { DocType, IUploadedFileResponse } from 'types/Upload';
import { TRANSLATION_KEYS } from 'utils/constants';

type Props = {
	documentType: DocType;
	data?: IUploadedFileResponse;
	openDialog: boolean;
	setOpenDialog: (open: boolean) => void;
};

const DeletePopup: FC<Props> = ({
	documentType,
	data,
	openDialog,
	setOpenDialog,
}) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<AppDispatch>();

	const { prescriptionSelectedFromStorage } = useSelector(
		(state: RootState) => state.claim,
	);

	const documentId = data?.id;
	const fileName = data?.fileName;
	const isPrescriptionFromStorage =
		documentType === 'prescription' && prescriptionSelectedFromStorage;

	const onDelete = () => {
		if (!documentId) return;

		if (isPrescriptionFromStorage) {
			dispatch(resetPrescriptionSelectedFromStorage());
			setOpenDialog(false);
			return;
		}

		dispatch(deleteDocument({ id: documentId, docType: documentType }));
		setOpenDialog(false);
	};

	return (
		<Dialog
			title={tClientLabels(`dialog.confirm.deleteUploadedFile.${documentType}`)}
			close={
				<IconButton onClick={() => setOpenDialog(false)}>
					<CloseDialog />
				</IconButton>
			}
			open={openDialog}
			variant="confirm"
			events
			actions={
				<>
					<Button variant="outlined" onClick={() => setOpenDialog(false)}>
						{tClientLabels(`dialog.buttons.cancel`)}
					</Button>
					<Button onClick={onDelete} variant="contained">
						{tClientLabels(`dialog.buttons.continue`)}
					</Button>
				</>
			}
			type=""
		>
			<Box display="flex" gap="1rem" flexDirection="column">
				<Typography variant="body">
					{isPrescriptionFromStorage ? (
						<>
							{tClientLabels(
								'dialog.confirm.deleteUploadedFile.content.prescriptionFromStorage',
								{
									docName: fileName ? fileName : '',
								},
							)}
						</>
					) : (
						<>
							{tClientLabels('dialog.confirm.deleteUploadedFile.content', {
								docName: fileName ? fileName : '',
							})}
						</>
					)}
				</Typography>
			</Box>
		</Dialog>
	);
};

export default DeletePopup;
