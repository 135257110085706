export interface IClaimState {
	errorCode: number;
	errorMessage: string;
	triggerRefresh: boolean;
	calculated: boolean;
	redirect: boolean;
	claimData: IClaim;
	statusCalculation: TStatusCalculation;

	prescriptionSelectedFromStorage: boolean;
	doctorTypes: { name: string; label: string }[];
}

export type TStatusCalculation =
	| 'beforeCalculation'
	| 'afterCalculation'
	| 'onEdit';

export interface IClaim {
	claimId?: number;
	externalClaimId: string;
	memberId: string;
	providerId: string;
	beneficiaryMemberId: string;
	beneficiarySubscriptionId: number;
	memberSubscriptionId: number;
	claimDate: string;
	step: number;
	// page 1
	exam: number;
	materials: number;
	lensType?: number;
	//page 2
	newPrescriptionContent?: IPrescription;
	oldPrescriptionContent?: IPrescription;
	prescriptionConsent: boolean;
	rxChangedWithoutOldPrescription: boolean | null;
	//page 3
	amount: {
		//basically everything is passed by calculate. only serviceid and retail amount is not
		components: IClaimComponents[];
		total: IClaimComponents;
		accumulator?: IClaimAccumulator;
	};
	rxChangeAttestation?: 0 | 1;
	uploadConsent: boolean;
	attachments: {
		prescription?: IFileType;
		receipt?: IFileType;
		others: IFileType[];
	};
	//useless
	memberFullName?: string;
	beneficiaryFullName?: string;
	memberFirstName?: string;
	memberLastName?: string;
	groupName?: string;
	memberProviderLocation?: string;
	claimStatusId?: 1 | 2 | 3 | 4 | 5 | 13 | 14;
	claimStatus?: string;
	planPayAmount?: number;
	memberPayAmount?: number;
	paidWith?: string;
	memberDesignation?: string;
	memberDesignationLabel?: string;
	planName?: string;
	oonProviderInfo?: {
		providerName?: string;
		providerLocation?: string;
		providerPhone?: string;
	};
	reimbursementInfo?: {
		ibanAccount?: string;
		bic?: string;
		bankName?: string;
		bankAddress?: string;
		beneficiaryName?: string;
	};
	signatureId?: number;
	servicesList?: string[];
}

export type IClaimComponents = {
	retailAmount: number | string;
	total?: number;
	contractualAdjustmentAmount?: number;
	memberPayAmount?: number;
	planPayAmount?: number;
	serviceName?: string;
	serviceId: null | 1 | 2 | 3 | 5;
	discountedAmount?: number;
	assessment?: number;
	allowablePlanPay?: number;
	coPay?: number;
	coInsurance?: number;
	disallowedAmount?: number;
	decliningBalance?: number;
	claimCostReduction?: number;
	inNetworkSave?: number;
	grossRetail?: number | string;
	inStorePromotion?: number | string;
};

export type IPrescription = {
	ava: number;
	prysm: number;
	decentralization: number;
	od: IEyePrescription;
	os: IEyePrescription;
	doctorTypology: string | null;
	prescriptionDate: string | null;
};

export type IEyePrescription = {
	sphere?: string;
	cylinder?: string;
	axis?: string;
	addition?: string;
	prismDioptresIO?: string;
	prismDirectionIO?: string;
	prismDioptresUD?: string;
	prismDirectionUD?: string;
	pd?: string;
	height?: string;
	vertexFitted?: string;
	faceformTilt?: string;
	pantoscopicTilt?: string;
};

type IClaimAccumulator = {
	benefitId: number;
	benefitType: string;
	accumulatorId: number;
	balance: number;
	maxUsableAmount: number;
	benefitUsed: number;
	planId: number;
	startDate: Date;
	endDate: Date;
	accumulatorType: string;
};

export type IFileType = {
	id: number;
	fileName?: string;
};

export enum Eye {
	od = 'od',
	os = 'os',
}

export const PRESCRIPTION_DEFAULT = {
	ava: 0,
	prysm: 0,
	decentralization: 0,
	doctorTypology: null,
	prescriptionDate: null,
	od: {
		sphere: '',
		cylinder: '',
		axis: '',
		addition: '',
		prismDioptresIO: '',
		prismDirectionIO: '',
		prismDioptresUD: '',
		prismDirectionUD: '',
		pd: '',
		height: '',
		vertexFitted: '',
		faceformTilt: '',
		pantoscopicTilt: '',
	},
	os: {
		sphere: '',
		cylinder: '',
		axis: '',
		addition: '',
		prismDioptresIO: '',
		prismDirectionIO: '',
		prismDioptresUD: '',
		prismDirectionUD: '',
		pd: '',
		height: '',
		vertexFitted: '',
		faceformTilt: '',
		pantoscopicTilt: '',
	},
};
