import { useTranslate } from 'stylesHooks';
import UploadFileTile from 'components/UploadFile/UploadFileTile';
import { FC } from 'react';
import { Stack } from 'styles';
import { IClaim } from 'types/Claim';
import { DocType } from 'types/Upload';
import { TRANSLATION_KEYS } from 'utils/constants';
import UploadManyTile from 'components/UploadFile/UploadManyTile';

type prescriptionProps = {
	formData: IClaim;
	docList: DocType[];
};

const Documentation: FC<prescriptionProps> = props => {
	const { formData, docList } = props;
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	return (
		<Stack direction={'row'} gap="40px" alignItems={'flex-start'}>
			{docList.map((doc, index) => {
				if (doc === 'other') {
					return (
						<UploadManyTile
							key={index}
							documentType={doc}
							data={formData.attachments['others']}
						/>
					);
				} else {
					return (
						<UploadFileTile
							key={index}
							label={tClientLabels(`newClaim.upload.${doc}`)}
							labelUpload={tClientLabels(`newClaim.upload.${doc}`)}
							documentType={doc}
							data={formData.attachments[doc]}
						/>
					);
				}
			})}
		</Stack>
	);
};

export default Documentation;
