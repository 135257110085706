import { editDocumentName } from 'feature/upload/uploadSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { Stack, TextField, Typography } from 'styles';
import { theme } from 'styles/theme/ThemeStyle';
import { fonts } from 'styles/theme/Typography';
import { IFileType } from 'types/Claim';
import { DocType } from 'types/Upload';

type EditorProps = {
	data?: IFileType;
	documentType: DocType;
	isEditMode: boolean;
	setIsEditMode: (editMode: boolean) => void;
};

const disallowedCharsRegex = /^[^\\/.*?]*$/;

const FileNameEditor: React.FC<EditorProps> = ({
	documentType,
	data,
	isEditMode,
	setIsEditMode,
}) => {
	const dispatch = useDispatch<AppDispatch>();

	const [fileNameField, setFileNameField] = useState('');

	const documentId = data?.id;
	const fileName = data?.fileName;

	const handlerFileNameAction = () => {
		// set this timeout in order to enable the toggle of the edit mode
		// otherwise when clicking on the pencil icon the edit mode is stuck in a loop
		setTimeout(() => {
			if (fileNameField && fileNameField.trim() !== fileName) {
				dispatch(
					editDocumentName({
						docType: documentType,
						documentId: documentId!,
						fileName: fileNameField.trim(),
					}),
				);
			}
			setIsEditMode(false);
		}, 100);
	};

	const handlerFileName = (evt: React.ChangeEvent<HTMLInputElement>) => {
		if (disallowedCharsRegex.test(evt.target.value)) {
			setFileNameField(evt.target.value);
		}
	};

	useEffect(() => {
		setFileNameField(fileName?.split('.')[0] || '');
	}, [fileName]);

	return isEditMode ? (
		<TextField
			onKeyDown={evt => {
				if (evt.key === 'Enter') {
					handlerFileNameAction();
				}
			}}
			onChange={handlerFileName}
			onBlur={handlerFileNameAction}
			autoFocus
			variant="standard"
			InputProps={{
				disableUnderline: true,
			}}
			sx={{
				width: '100%',
				'.MuiInput-input': {
					backgroundColor: `${
						documentType === 'other'
							? theme.palette.white.main
							: theme.palette.grey6.main
					}`,
					height: '24px',
					fontFamily: fonts.regular,
					fontWeight: 400,
					letterSpacing: '0.2px',
					fontSize: '11px',
					lineHeight: '16px',
					padding: 0,
					paddingLeft: '1rem',
				},
			}}
			value={fileNameField}
		/>
	) : (
		<Stack alignItems="center">
			<Typography
				overflow="hidden"
				maxWidth={{
					sm: '150px',
					lg: '300px',
				}}
				whiteSpace={'nowrap'}
				textOverflow="ellipsis"
				textAlign="center"
				variant={'caption'}
				color={theme.palette.grey2.main}
				lineHeight={'24px !important'}
			>
				{fileName}
			</Typography>
		</Stack>
	);
};

export default FileNameEditor;
