import { ReactComponent as Basket } from 'assett/icons/basket.svg';
import { ReactComponent as Pencil } from 'assett/icons/pencil.svg';
import { ReactComponent as UploadCompleted } from 'assett/icons/upload_completed.svg';
import { FC, useState } from 'react';
import { IconButton, Stack } from 'styles';
import { IFileType } from 'types/Claim';
import { DocType } from 'types/Upload';
import DeletePopup from './DeletePopup';
import FileNameEditor from './FileNameEditor';

type AttachmentProps = {
	data?: IFileType;
	documentType: DocType;
	fileName?: string;
};

const ManyAttachmentRow: FC<AttachmentProps> = ({
	data,
	fileName,
	documentType,
}) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [dialogDeleteOpened, setDialogDeleteOpened] = useState<boolean>(false);

	const enableEditModeAction = () => {
		setIsEditMode(true);
	};

	return (
		<>
			{data ? (
				<DeletePopup
					data={{ id: data.id, fileName: data.fileName || '' }}
					documentType={documentType}
					openDialog={dialogDeleteOpened}
					setOpenDialog={setDialogDeleteOpened}
				/>
			) : null}
			<Stack direction={'row'} justifyContent={'space-between'} gap={'8px'}>
				<Stack direction={'row'} gap={'15px'} width={'100%'}>
					<UploadCompleted width={'24px'} height={'24px'} />
					<FileNameEditor
						documentType={documentType}
						data={data}
						isEditMode={isEditMode}
						setIsEditMode={setIsEditMode}
					/>
				</Stack>
				<Stack direction={'row'} gap={'20px'}>
					<IconButton onClick={enableEditModeAction}>
						<Pencil width={'24px'} height={'24px'} />
					</IconButton>
					<IconButton disableTouchRipple onClick={() => setDialogDeleteOpened(true)}>
						<Basket width={'24px'} height={'24px'} />
					</IconButton>
				</Stack>
			</Stack>
		</>
	);
};

export default ManyAttachmentRow;
