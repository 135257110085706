import {
	Dialog as DialogMui,
	DialogProps as DialogPropsMui,
} from '@mui/material';

import React from 'react';
import styled from 'styled-components';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogTitle from './DialogTitle';

type DialogProps = DialogPropsMui & {
	title: string;
	variant: 'dialog' | 'confirm' | 'uploadFileDialog';
	actions: React.ReactNode;
	close: React.ReactNode;
	events: boolean;
	type: string;
};

const StyledDialog = styled(DialogMui)``;

const Dialog: React.FC<DialogProps> = props => {
	const { title, variant, actions, close, events, type, ...dialogProps } = props;

	return (
		<StyledDialog
			disableRestoreFocus
			closeAfterTransition
			{...dialogProps}
			PaperProps={{ variant: variant }}
		>
			{title && <DialogTitle title={title} children={close} />}
			<DialogContent>{dialogProps.children}</DialogContent>
			{!!events && <DialogActions>{actions}</DialogActions>}
		</StyledDialog>
	);
};

export default Dialog;
