import { ThunkApiType } from 'types/storeTypes';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { getLocaleLanguage } from 'utils/utils';

export const getDocumentsThunk = async (_: any, thunkAPI: ThunkApiType) => {
	const lang = getLocaleLanguage();

	const getDocumentsURL = `/cms/manuals?locale=${lang}&populate=*`;

	try {
		const response = await authFetch.get(getDocumentsURL);
		return response.data?.data || undefined;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
