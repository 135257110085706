import {
	Dialog as DialogMui,
	DialogProps as DialogPropsMui,
	DialogTitle,
	styled,
} from '@mui/material';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useTheme } from 'stylesHooks';
import DialogContent from './DialogContent';

const StyledDialog = styled(DialogMui)``;

type Props = DialogPropsMui & {
	title: string;
	close: React.ReactNode;
	actions: React.ReactNode;
};

const DialogDesktop = (props: Props) => {
	const { title, close, actions, sx, ...dialogProps } = props;
	const theme = useTheme();

	return (
		<StyledDialog
			disableRestoreFocus
			closeAfterTransition
			{...dialogProps}
			sx={sx as object}
		>
			<DialogTitle
				component={'div'}
				borderBottom={`1px solid ${theme.palette.grey3.main}`}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					backgroundColor: theme.palette.white.main,
					paddingX: {
						xl: '32px',
						lg: '32px',
						md: '20px',
					},
					paddingY: '15px',
					height: '64px',
				}}
			>
				<Typography variant="title5">{title}</Typography>
				{close}
			</DialogTitle>
			<DialogContent
				sx={{
					overflow: 'hidden',
					m: '0!important',
					p: '0!important',
					mb: {
						xl: actions ? '86px!important' : '0',
						lg: actions ? '86px!important' : '0',
						md: actions ? '72px!important' : '0',
					},
				}}
			>
				{dialogProps.children}
			</DialogContent>
			{actions && (
				<Stack
					bgcolor={theme.palette.white.main}
					position={'absolute'}
					bottom={0}
					width={'100%'}
					direction={'row'}
					justifyContent={'flex-end'}
					gap={'20px'}
					py={'15px'}
					px={'20px'}
				>
					{actions}
				</Stack>
			)}
		</StyledDialog>
	);
};

export default DialogDesktop;
