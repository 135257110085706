import { ReactComponent as UploadIcon } from 'assett/icons/upload_arrow_icon.svg';
import { setDocumentTypeSelected } from 'feature/upload/uploadSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useUploadFile } from 'hooks/uploadFile.hook';
import { ChangeEvent, DragEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { Box, Button, Stack, Typography } from 'styles';
import { useTranslate } from 'stylesHooks';
import { DocType } from 'types/Upload';
import { TRANSLATION_KEYS } from 'utils/constants';

type Props = {
	documentType: DocType;
	maxFileNumber?: number;
};

const BeforeUpload = ({ documentType, maxFileNumber = 1 }: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const { isTabletDevice } = useDevice();

	const uploadFileHandler = useUploadFile();

	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const handleChange = (
		e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
	) => {
		dispatch(setDocumentTypeSelected(documentType));
		uploadFileHandler.upload(e, documentType, maxFileNumber);
		//to be able to upload files with the same name
		if (fileInputRef?.current) {
			fileInputRef.current.value = '';
		}
	};

	const handleDrag = function (evt: DragEvent<HTMLDivElement>) {
		uploadFileHandler.drag(evt, maxFileNumber);
	};

	const handleDrop = function (evt: DragEvent<HTMLDivElement>) {
		uploadFileHandler.drop(evt, handleChange, maxFileNumber);
	};

	const ButtonText = (
		<Stack direction={'row'}>
			<Typography
				sx={{ textDecoration: 'underline', textUnderlineOffset: '5px' }}
				variant="button"
			>
				{tClientLabels(`newClaim.beforeUpload.${documentType}`)}
			</Typography>
		</Stack>
	);

	const ErrorText = uploadFileHandler.dragStatus.onError && (
		<Typography
			variant="caption"
			color={'error'}
			sx={{
				display: 'block',
				textTransform: 'none',
				width: isTabletDevice ? '100%' : '360px',
			}}
			mt={1}
		>
			{uploadFileHandler.dragStatus.errorMessage}
		</Typography>
	);

	return (
		<Box
			component={'label'}
			tabIndex={-1}
			onDragEnter={handleDrag}
			position={'relative'}
			sx={{
				width: '100%',
			}}
		>
			<Button
				variant="upload"
				sx={{
					width: '100%',
					height: { xs: '64px', lg: '200px' },
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					gap: '25.5px',
					borderColor: uploadFileHandler.dragStatus.onError
						? theme => theme.palette.error.main
						: 'inherit',
				}}
				component={'label'}
				tabIndex={-1}
			>
				<UploadIcon width={48} height={48} />

				<Stack gap="13px" alignItems={'center'}>
					<Typography variant="value3" sx={{ textTransform: 'none' }}>
						{tClientLabels(
							`uploadFileDialog.dragAndDrop${documentType === 'other' ? 'S' : ''}`,
						)}
					</Typography>

					{ButtonText}
				</Stack>
				<input
					hidden
					id={`fileInput_${documentType}`}
					type="file"
					multiple={maxFileNumber > 1}
					accept="*"
					onChange={handleChange}
					ref={fileInputRef}
				/>
			</Button>
			{uploadFileHandler.dragStatus.isActive && (
				<Box
					position="absolute"
					width="100%"
					height="100%"
					top={0}
					left={0}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				/>
			)}
			{ErrorText}
		</Box>
	);
};

export default BeforeUpload;
